// 注文番号から注文情報詳細の取得

import api from '../api'

const path = '/orders/{code}'

export default code => {
  return api({
    url: path.replace('{code}', code),
    auth: true
  })
}
