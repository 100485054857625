// 注文完了画面

<template lang="pug">
.order-completed

  template(v-if='order')

    h2.text-3xl.font-bold.text-teal-700.lg__text-center ご注文完了

    p.mt-5
      | ご注文ありがとうございました。
      br
      | ご注文番号は
      b 「{{ order.code }}」
      | です。
      br
      | また、ご登録メールアドレス宛にご注文内容確認メールを送信しました。

    //- コンビニ払いの場合
    section.mt-5.border-2.bg-yellow-100.p-3(v-if='conveniInfo')
      h3.text-center.font-bold.text-red-500.text-lg (重要)コンビニ決済のお支払方法
      p.mt-2 {{ conveniNativeInfo.description }}

      table.border-collapse.border.mx-auto.mt-3
        tbody
          tr
            th.border.bg-gray-300.p-2 お支払先
            td.border.bg-white.p-2 {{ conveniInfo.name }}
        tbody
          tr
            th.border.bg-gray-300.p-2 お支払期限
            td.border.bg-white.p-2  {{ conveniInfo.expiresJp }}
        tbody(v-if='conveniInfo.companyCode')
          tr
            th.border.bg-gray-300.p-2 企業コード
            td.border.bg-white.p-2  {{ conveniInfo.companyCode }}
        tbody
          tr
            th.border.bg-gray-300.p-2 {{ conveniNativeInfo.numberName }}
            td.border.bg-white.p-2  {{ conveniInfo.conveniCode }}
        tbody(v-if='conveniInfo.slipUrl')
          tr
            th.border.bg-gray-300.p-2 払込票の印刷
            td.border.bg-white.p-2
              | 印刷をご希望の場合は
              a.underline.text-blue-700(:href='conveniInfo.slipUrl', target='_blank', rel='noopener') こちらへ

  p(v-else) 読み込み中...

  footer.text-center.mt-12
    router-link.btn.mt-4(
      :to='{ name: "MyHome" }'
    ) マイページへ
</template>

<script>
import { mapActions } from 'vuex'
import get from 'lodash.get'

import getOrderApi from '../../api/orders/get'
import conveniNativeInfo from '../../utils/conveni-info'
import errorCodes from '../../errors/codes'

export default {
  name: 'OrderCompleted',

  data () {
    return {
      order: null
    }
  },

  computed: {
    orderCode () {
      return this.$route.params.orderCode
    },
    conveniNativeInfo () {
      if (get(this.order, 'payment.code') !== 'conveni') return null
      return conveniNativeInfo[this.order.payment.conveni.code]
    },
    conveniInfo () {
      return get(this.order, 'payment.conveni')
    }
  },

  async mounted () {
    const response = await getOrderApi(this.orderCode)
    if (!response.ok) {
      this.setError({ code: errorCodes.GET_ORDER_INFO_ERROR })
      return
    }
    this.order = response.payload
  },

  methods: {
    ...mapActions({
      setError: 'app/setError'
    })
  }
}
</script>

<style lang="sass" scoped>

</style>
