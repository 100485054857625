// 各コンビニの情報

export default {
  seven: {
    code: 'seven',
    name: 'セブンイレブン',
    numberName: '払込票番号',
    description: '払込票をプリントアウトする、または払込票番号を控えて、レジにて代金をお支払いください。'
  },
  famima: {
    code: 'famima',
    name: 'ファミリーマート',
    numberName: '注文番号',
    description: '店内に設置されているFamiポート／ファミネットにて「企業コード」と「注文番号」を入力し、申込券を印字後、レジにて代金をお支払い下さい。'
  },
  lawson: {
    code: 'lawson',
    name: 'ローソン',
    numberName: '受付番号',
    description: '店内に設置されているLoppiにて「受付番号」を入力し、申込券を印字後、レジにて代金をお支払い下さい。'
  },
  mini: {
    code: 'mini',
    name: 'ミニストップ',
    numberName: '受付番号',
    description: '店内に設置されているLoppiにて「受付番号」を入力し、申込券を印字後、レジにて代金をお支払い下さい。'
  }
}
